import Image from 'next/image';
import Link from 'next/link';

export function Logo(props) {
  return (
    <div className="flex lg:flex-1 text-xl md:text-2xl font-bold">
      <Link href="/" className="-m-1.5 p-1.5 text-black no-underline">
        <div className="flex gap-2">
          <Image
            className="my-auto"
            src={`/images/logo_32x32.png`}
            alt="Medical Chat Icon"
            width={32}
            height={32}
          />
          <div>Medical Chat</div>
        </div>
      </Link>
    </div>
  );
}
