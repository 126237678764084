import Link from 'next/link';

export function NavLink({ href, children, ...rest }) {
  return (
    <Link
      href={href}
      {...rest}
      className="inline-block leading-6 rounded-lg px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-slate-100"
    >
      {children}
    </Link>
  );
}
